import { NavBar } from '@components/navbar/NavBar';
import { Button, ReusableComponentProps } from '@packfleet/ui';
import cs from 'classnames';
import React, { ReactNode } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useLoggedInAs } from '../../hooks/useLoggedInAs';
import { logoutAs } from '../../utilities/request/auth';
import HeadTag, { Meta } from '../head-tag/HeadTag';
import { PackfleetProModal } from '../packfleet-pro/PackfleetProModal';

export type Props = ReusableComponentProps & {
  title: string;
  customHeader?: React.ReactNode;
  fullHeight?: boolean;
  fixedHeight?: boolean;
  sidebar?: React.ReactElement;
  meta?: Meta;
  hideNavbar?: boolean;
  intercomEnabled?: boolean;
  robotsIndexEnabled?: boolean;
  children?: ReactNode;
};

const Page = ({
  title,
  children,
  className,
  fullHeight,
  fixedHeight,
  style,
  hideNavbar,
  meta = {},
  intercomEnabled = false,
  robotsIndexEnabled = false,
}: Props) => {
  const { loggedInAs, impersonatedBy } = useLoggedInAs();

  const intercom = useIntercom();
  if (intercomEnabled && !impersonatedBy) {
    intercom.boot();
  } else {
    intercom.shutdown();
  }

  return (
    <>
      <HeadTag
        title={title}
        meta={meta}
        robotsIndexEnabled={robotsIndexEnabled}
      />
      {hideNavbar ? null : <NavBar />}
      <div
        className={cs('w-full flex-col bg-body md:flex-row md:pt-0', {
          'pt-16 md:pl-16': !hideNavbar,
        })}
        style={{
          minHeight: fullHeight ? '100%' : undefined,
          height: fixedHeight ? '100%' : undefined,
          ...style,
        }}
      >
        <main
          className={cs('relative flex w-full', className)}
          style={{
            minHeight: fullHeight ? '100%' : undefined,
            height: fixedHeight ? '100%' : undefined,
            ...style,
          }}
        >
          <>
            <PackfleetProModal />
            {children}
          </>
        </main>
      </div>
      {impersonatedBy && loggedInAs ? (
        <>
          <div className="fixed bottom-0 w-full bg-warning p-2 text-center text-primaryInverted">
            <div className="flex items-center justify-center">
              <div>
                Youʼre logged in as {loggedInAs.email} (
                {loggedInAs.organization?.name}
                ).
              </div>
              <div>
                <form
                  method="POST"
                  onSubmit={async (e) => {
                    e.preventDefault();
                    await logoutAs();
                    window.location.reload();
                  }}
                >
                  <Button
                    type="submit"
                    s="smaller"
                    color="neutral"
                    mode="secondary"
                    className="ml-2"
                  >
                    Stop running as
                  </Button>
                </form>
              </div>
            </div>
          </div>
          <div className="fixed bg-warning z-20 top-0 bottom-0 w-4 left-16" />
          <div className="fixed bg-warning z-20 top-0 bottom-0 w-4 right-0" />
          <div className="fixed bg-warning z-20 left-0 right-0 h-4 top-0" />
        </>
      ) : null}
    </>
  );
};

export default Page;

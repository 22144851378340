import Head from 'next/head';
import React, { FunctionComponent } from 'react';

const defaultTitle = 'Packfleet App';
const defaultDescription = '';

export type Meta = {
  description?: string;
  imageURL?: string;
  pageURL?: string;
};

export type Props = {
  title?: string;
  meta: Meta;
  robotsIndexEnabled?: boolean;
};

const HeadTag: FunctionComponent<Props> = ({
  title,
  meta: { description },
  robotsIndexEnabled,
}) => {
  return (
    <Head>
      <title>{title || defaultTitle}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0 maximum-scale=1.0, user-scalable=no"
      />
      <meta name="description" content={description || defaultDescription} />
      <meta
        name="robots"
        content={robotsIndexEnabled ? '' : 'noindex, nofollow'}
      />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />

      {/* Plausible */}
      <script
        defer
        data-domain="app.packfleet.com"
        src="https://plausible.io/js/plausible.js"
      ></script>
    </Head>
  );
};

export default HeadTag;

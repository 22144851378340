import { PostHog, useActiveFeatureFlags, usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';

function getFeatureSessionStorageKey(featureFlagName: string): string {
  return `ph-popup-${featureFlagName}`;
}

function shouldShowPopup(featureFlagName: string): boolean {
  // The feature flag should have be disabled for the user once the popup has been shown
  // This is a second check for shorter-term preventing of the popup from showing
  const flagNotShownBefore = !localStorage.getItem(
    getFeatureSessionStorageKey(featureFlagName),
  );

  return flagNotShownBefore;
}

function sendPopupEvent(
  event: string,
  flag: string,
  posthog: PostHog,
  buttonType?: string,
) {
  if (buttonType) {
    posthog.capture(event, {
      popupFlag: flag,
      popupButtonPressed: buttonType,
    });
  } else {
    posthog.capture(event, {
      flag: flag,
    });
  }
}

export function usePopup(flag: string) {
  const [open, setOpen] = useState(false);
  const posthog = usePostHog();
  const activeFlags = useActiveFeatureFlags();

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    let reattempts = 0;

    const initPopup = () => {
      if (posthog?.isFeatureEnabled(flag)) {
        if (shouldShowPopup(flag)) {
          setOpen(true);

          sendPopupEvent('popup shown', flag, posthog);
        }
      } else if (!activeFlags && reattempts < 5) {
        // Temporary hack to ensure feature flags are loaded
        // TODO: Replace with event listener once available in PostHog SDK
        reattempts += 1;
        timeout = setTimeout(() => initPopup(), 1000);
      }
    };
    initPopup();

    return () => timeout && clearTimeout(timeout);
  }, [flag, posthog, activeFlags]);

  const onClose = (buttonType?: string) => {
    setOpen(false);

    localStorage.setItem(getFeatureSessionStorageKey(flag), 'true');
    posthog.people.set({ ['$' + flag]: new Date().toDateString() });
    posthog.setPersonPropertiesForFlags({
      ['$' + flag]: new Date().toDateString(),
    });

    sendPopupEvent('popup closed', flag, posthog, buttonType);
  };

  return {
    open,
    onClose,
  };
}

import {
  useCurrentUser,
  useIsBrandUser,
  useUserRole,
} from '@/hooks/useCurrentUser';
import { useLocalFlag } from '@/hooks/useLocalFlag';
import { EventName, useAnalytics } from '@packfleet/analytics';
import { Tooltip } from '@packfleet/ui';
import cs from 'classnames';
import { differenceInDays } from 'date-fns';
import { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import { useRouter } from 'next/router';
import React, { ReactElement, ReactNode } from 'react';
import { Routes, route } from '../../utilities/routes';
import { useCountImportableShipments } from '../inbox/useCountImportableShipments';
import Link from '../link/Link';
import { Icons } from './Icons';

const ImportableShipmentsBadge = () => {
  const { totalImportableShipments, importableShipmentsIsPaginated } =
    useCountImportableShipments();

  if (!totalImportableShipments) {
    return null;
  }

  return (
    <span className="animation-scaledFadeIn absolute -top-1.5 -right-2 z-10 flex h-3.5 items-center justify-center rounded-full bg-attention px-1 text-xxs font-medium text-primaryInverted">
      <span className="inline-block">
        {totalImportableShipments}
        {importableShipmentsIsPaginated ? '+' : undefined}
      </span>
    </span>
  );
};

const referVersion = '14-01-2022';
const referKey = 'packfleet-refer';

const WithReferralBadge = ({ children }: { children?: ReactNode }) => {
  const [seenRefer, setSeenRefer] = useLocalFlag(referKey, referVersion);
  const analytics = useAnalytics();
  return (
    <div
      className="relative"
      onClick={() => {
        analytics?.track(EventName.clickedMerchantReferral25OffSidebarButton);
        setSeenRefer();
      }}
    >
      {!seenRefer ? (
        <div className="animation-scaledFadeIn absolute -top-1.5 -right-2 z-10 flex h-2 items-center justify-center rounded-full bg-attention px-1 text-xxs font-medium text-primaryInverted" />
      ) : null}
      {children}
    </div>
  );
};

type NavItem = {
  href: string;
  title: string;
  icon: ReactElement;
  alignment: 'start' | 'end';
  className?: string;
};

const getNavigationItems = (
  query: NextParsedUrlQuery,
  showReferLink: boolean,
  isBrandUser: boolean,
  hasWriteRole: boolean,
) =>
  [
    {
      href: route(Routes.home),
      title: 'Dashboard',
      icon: (
        <span className="block overflow-hidden rounded">{Icons.dashboard}</span>
      ),
      alignment: 'start',
    },
    hasWriteRole
      ? {
          href: route(Routes.appShipments),
          title: 'Add shipments',
          icon: (
            <>
              <ImportableShipmentsBadge />
              {Icons.shipments}
            </>
          ),
          alignment: 'start',
        }
      : undefined,
    {
      href: route(Routes.appShipmentsToCollect, {
        locationId: query.locationId,
        slug: query.slug,
      }),
      title: 'To collect',
      icon: Icons.collections,
      alignment: 'start',
    },
    {
      href: route(Routes.appShipmentsOnTheirWay, {
        locationId: query.locationId,
        slug: query.slug,
      }),
      title: 'On their way',
      icon: Icons.onTheirWay,
      alignment: 'start',
    },
    {
      href: route(Routes.appShipmentsDelivered, {
        locationId: query.locationId,
        slug: query.slug,
      }),
      title: 'Delivered',
      icon: Icons.delivered,
      alignment: 'start',
    },
    // Bottom,
    !isBrandUser
      ? {
          href: route(Routes.appAnalytics),
          title: 'Analytics',
          icon: Icons.analytics,
          alignment: 'end',
        }
      : undefined,
    showReferLink
      ? {
          href: 'https://blog.packfleet.com/refer',
          title: 'Refer to get 25% off',
          icon: <WithReferralBadge>{Icons.referral}</WithReferralBadge>,
          alignment: 'end',
        }
      : undefined,
    {
      href: route(Routes.appSettings),
      title: 'Settings',
      icon: Icons.settings,
      alignment: 'end',
    },
  ].filter((item): item is NavItem => !!item);

const NavLink = ({
  children,
  href,
  className,
  title,
}: {
  children?: ReactNode;
  href: string;
  className?: string;
  title: string;
}) => {
  const router = useRouter();

  const isActive = router.pathname === href.split('?')[0];

  return (
    <Tooltip
      title={title}
      position="right"
      // slate1
      color="#fbfcfd"
      offset={-11}
      className="outline-brandDark z-30 -ml-1 hidden border border-slate7 px-3 font-medium text-green10 shadow-center md:flex"
      arrowHeight={10}
      arrowWidth={20}
      asChild={false}
    >
      <Link
        className={cs(
          'flex h-full items-center justify-center px-3 py-2 md:w-16 md:p-4',
          className,
          {
            'bg-green10 text-green11': isActive,
            'text-green9 hover:bg-green10 hover:text-green11': !isActive,
          },
        )}
        href={href}
        unstyled
      >
        {children}
      </Link>
    </Tooltip>
  );
};

export const NavBar = () => {
  const router = useRouter();
  const user = useCurrentUser();
  const { isBrandUser } = useIsBrandUser();
  const { hasWriteRole } = useUserRole();

  // Only show the referral link to accounts younger than 30 days
  const accountCreatedDaysAgo = user?.organization?.createdAt
    ? differenceInDays(new Date(), new Date(user?.organization?.createdAt))
    : 0;
  const showReferLink = accountCreatedDaysAgo > 30;

  const navItems = getNavigationItems(
    router.query,
    showReferLink,
    isBrandUser,
    hasWriteRole,
  );

  return (
    <div className="fixed top-0 z-30 flex h-14 w-full shrink-0 justify-between bg-green12 md:h-screen md:w-16 md:flex-col md:items-start md:pb-2">
      <div className="flex md:flex-col">
        {navItems
          .filter(({ alignment }) => alignment !== 'end')
          .map(({ href, title, icon }) => (
            <NavLink key={title} href={href} title={title}>
              <span className="relative">{icon}</span>
            </NavLink>
          ))}
      </div>
      <div className="flex h-14 w-full justify-end md:h-auto md:flex-col">
        {navItems
          .filter(({ alignment }) => alignment === 'end')
          .map(({ href, title, icon, className }) => (
            <NavLink
              key={title}
              href={href}
              title={title}
              className={className}
            >
              <span className="relative">{icon}</span>
            </NavLink>
          ))}
      </div>
    </div>
  );
};
